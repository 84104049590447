import '../scss/theme.scss';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import './jquery.rwdImageMaps.min.js';
// import './sticky-header.js';

//Images
import '../image/map.png';
import '../image/content-trees.png';
import '../image/footer-trees.png';
import '../image/HTOL_default_image.jpeg'; 
import '../image/HTOL-logo.png';
import '../image/internal-header.png';
import '../image/lets-go.png';
import '../image/logo.svg';
import '../image/plan-your-trip-lg.png';
import '../image/plan-your-trip-sm.png';
import '../image/scroll-text.png';

jQuery(document).ready(function (jQuery) {
	//Elements to inject
	var elementsToInject = document.querySelectorAll('.inject-me');
	// Options
	var injectorOptions = {
		evalScripts: 'once'
	};
	// Trigger the injection
	var injector = new SVGInjector(injectorOptions);
	if (jQuery(elementsToInject).length) {
		injector.inject(
			elementsToInject
		);
	}
});

	// MAP SHOW REGIONS
	jQuery('.map-region').on('click mouseover', function(e){
	var self      = $(this),
		targetSel = self.attr('data-id'),
		target    = $(targetSel);
	jQuery('.city-block').removeClass('city-block-active');
	target.addClass('city-block-active');
	});



//Get the button
jQuery(document).ready(function (jQuery) {
let mybutton = document.getElementById("btn-back-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  if (
    document.body.scrollTop > 20 ||
    document.documentElement.scrollTop > 20
  ) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}
// When the user clicks on the button, scroll to the top of the document
mybutton.addEventListener("click", backToTop);

function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
});

jQuery(document).ready(function() {
    jQuery(document).on('fancybox-cleanup', function() {
        jQuery('video,audio').trigger('pause');
    });
    jQuery('.video-play').on('click', function() {
        let vidTrigger = this.getAttribute('data-id');
        let video = document.querySelector(`#${vidTrigger} video`);
        console.log(video);
        jQuery(video).trigger('play');
    });
});

jQuery.fn.exists = function (callback) {
	var args = [].slice.call(arguments, 1);

	if (this.length) {
		callback.call(this, args);
	}

	return this;
};